<template>
  <div class="all" v-loading.fullscreen.lock="loading" :element-loading-text="loadingTip" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <Nav></Nav>
    <div class="uppost desktop-present" ref="upPage">
      <input ref="upfileSelector" type="file" multiple="multiple" accept=".jpg, .jpeg,.png.JPG,.JPEG,.PNG" class="invisible" @change="fileChange($event)" />
      <input ref="upPftfileSelector" type="file" multiple="multiple" accept=".pdf, .PDF" class="invisible" @change="filePdfChange($event)" />
      <div class="uppost-con">
        <div class="uppost-title">首页>上传图片</div>
        <div class="uppost-detail">
          <div class="tip">
            <div class="red">注意事项：</div>
            <div>请尽量使用IE或者火狐浏览器进行上传，保证兼容性。 IE需要版本10以上。请点击"选择图片"进行图片选择。可以多选，只接受jpg，jpeg后缀名文件。</div>
            <div>选择图片后，图片会依次上传。图片大小不得超过50MB。</div>
            <div>上传过程中，您可以对文章进行编辑，修改，可以调整图片的顺序。</div>
            <div v-if="canGoRich">如果要上传富文本稿件，请点<a href="upPostRich.html" target="_self">此处</a></div>
            <!-- <div class="red">
              您也可以直接拖动文件到此页面进行文件上传！（此功能在某些版本浏览器上可能无法使用）
            </div> -->
          </div>
          <div class="small-pic-preview">
            <img v-for="pic in picList" :src="pic.showUrl" :key="'pic_small_' + pic.key" />
          </div>
          <div class="small-pdf-preview">
            <img v-for="pic in pdfList" :src="pic.showUrl" :key="'pic_small_' + pic.key" />
          </div>
          <div class="add-pic">
            <el-button @click="onAddPic()">添加图片</el-button>
            <!-- <el-button v-if="pdfShow" @click="onAddPdf">添加PDF图片</el-button> -->
            <!-- <el-button @click="onUsePicFileAsPicText()">以文件名作为图片说明</el-button> -->
          </div>
          <!-- 图片总上传进度条 -->
          <div class="allProgress-ontainer" v-if="allProgress != 0">
            <div class="all-progress">
              <div class="progress" :style="{ width: allProgress + '%' }" v-bind:class="{ green: allProgress == 100 }"></div>
            </div>
            <div class="small-progress">图片上传总进度：{{ allProgress + '%' }}</div>
          </div>
          <div class="up-con">
            <div class="up-left">
              <div>
                <span class="title">新闻标题：</span>
                <el-input v-model="post.title" placeholder ref="postTitle"></el-input>
              </div>
              <div>
                <span class="title">目标稿库：</span>
                <el-radio-group v-model="post.targetStore" class="contentradio">
                  <el-radio v-for="upStore in userInfo.upPicStores" :key="'store_' + upStore.id" class="radio" :label="upStore.id">{{ upStore.name }}</el-radio>
                </el-radio-group>
              </div>
              <div>
                <span class="title">发生时间：</span>
                <el-date-picker v-model="post.eventTime" type="date" value-format="yyyy-MM-dd" :default-value="new Date()" placeholder="选择日期"></el-date-picker>
              </div>
              <div>
                <span class="title">发生地点：</span>
                <el-cascader
                  class="content"
                  :options="$rtm.store.addrTree"
                  :props="types_picker_props"
                  filterable
                  clearable
                  separator=" > "
                  v-model="addr_picker_arr"
                  popper-class="addr-select-popper"
                ></el-cascader>
              </div>
              <div>
                <span class="title">稿件类型1：</span>
                <el-select v-model="type_picker_arr1" filterable placeholder="请选择" clearable>
                  <el-option v-for="item in $rtm.store.typeTree" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
                <!-- <el-cascader :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr1"></el-cascader> -->
              </div>
              <div>
                <span class="title">稿件类型2：</span>
                <el-select v-model="type_picker_arr2" filterable placeholder="请选择" clearable>
                  <el-option v-for="item in $rtm.store.typeTree" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
                <!-- <el-cascader :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr2"></el-cascader> -->
              </div>
              <div>
                <span class="title">稿件类型3：</span>
                <el-select v-model="type_picker_arr3" filterable placeholder="请选择" clearable>
                  <el-option v-for="item in $rtm.store.typeTree" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
                <!-- <el-cascader :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr3"></el-cascader> -->
              </div>
              <div>
                <span class="title">署名：</span>
                <el-input class="half" v-model="post.author" placeholder></el-input><span class="input-tip">（署名必须是中文）</span>
              </div>
              <div class="activity" v-if="atopics.length != 0">
                <span>参加投稿活动:</span>
                <el-checkbox-group v-model="acvititycCheck" @change="onChangeAt">
                  <el-checkbox v-for="acvitity in atopics" :key="acvitity.id" :label="acvitity.id"
                    >{{ acvitity.shortTitle }}<a :href="'activityPage.html?code=' + acvitity.id" target="_blank">(打开活动)</a></el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="up-right">
              <div>
                稿件正文：
                <el-input v-model="post.mainText" type="textarea" :rows="7" ref="postMainText"></el-input>
              </div>
              <div>
                备注：
                <el-input v-model="post.note" type="textarea" :rows="4" ref="postNote"></el-input>
              </div>
              <el-button type="primary" class="save" @click="onSavePost()">保存</el-button>
            </div>
          </div>
          <div class="add-pic">
            <el-button @click="onAddPic()">添加图片</el-button>
            <!-- <el-button v-if="pdfShow" @click="onAddPdf">添加PDF图片</el-button> -->
            <!-- <el-button @click="onUsePicFileAsPicText()">以文件名作为图片说明</el-button> -->
          </div>
          <div class="pic-list">
            <!-- <div class="pic-item" v-for="(pic, picIndex) in picList" :key="pic.key" :class="{ moveUp: pic.moveUp, moveDown: pic.moveDown }"> -->
            <div class="pic-item" v-for="(pic, picIndex) in picList" :key="pic.key" :class="{ moveUp: pic.moveUp, moveDown: pic.moveDown }">
              <div class="othee">
                <div :style="{ backgroundImage: 'url(' + pic.showUrl + ')' }" class="pic-image">
                  <i class="el-icon-top up" @click="onUpImage(pic.key)"></i>
                  <i class="el-icon-close close" @click="onDeleteImage(pic.key)"></i>
                  <i class="el-icon-bottom down" @click="onDownImage(pic.key)"></i>
                </div>
                <div class="pic-info">
                  <div class="progress-container">
                    <div class="progress" :style="{ width: pic.progress + '%' }" v-bind:class="{ green: pic.done == true }"></div>
                  </div>
                  <div class="status-line">
                    <el-button :disabled="!pic.textMsg" @click="onCopyPicText(picIndex)">复制本说明到其他无说明的图片</el-button>
                    文件名：{{ pic.fileName }} <span>状态：{{ pic.done ? '已上传完毕' : '已上传' + pic.progress + '%' }}</span>
                    <span v-if="!pic.textMsg" class="nopictext">(未填写图片小说明)</span>
                  </div>
                  <el-input type="textarea" class="text" :rows="4" placeholder="请输入图片说明" v-model="pic.text" resize="none" @input="onChangePicText(picIndex)"></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uppost mobile-present" ref="upPage">
      <input ref="upfileSelector" type="file" multiple="multiple" class="invisible" @change="fileChange($event)" />
      <div class="uppost-con">
        <div class="uppost-title">首页>上传图片</div>
        <div class="uppost-detail">
          <div class="tip">
            <div class="red">注意事项：</div>
            <div>请尽量使用IE或者火狐浏览器进行上传，保证兼容性。 IE需要版本10以上</div>
            <div>请点击"选择图片"进行图片选择。可以多选，只接受jpg，jpeg后缀名文件。</div>
            <div>选择图片后，图片会依次上传。图片大小不得超过50MB。</div>
            <div>上传过程中，您可以对文章进行编辑，修改，可以调整图片的顺序。</div>
            <div v-if="canGoRich">如果要上传到默认富文本库，请点<a href="upPostRich.html" target="_self">此处</a></div>
            <!-- <div class="red">
              您也可以直接拖动文件到此页面进行文件上传！（此功能在某些版本浏览器上可能无法使用）
            </div> -->
          </div>
          <div class="small-pic-preview">
            <img v-for="pic in picList" :src="pic.url" :key="'pic_small_' + pic.key" />
          </div>
          <div class="add-pic">
            <el-button @click="onAddPic()">添加图片</el-button>
            <!-- <el-button @click="onUsePicFileAsPicText()">以文件名作为图片说明</el-button> -->
          </div>
          <!-- 图片总上传进度条 -->
          <div class="allProgress-ontainer" v-if="allProgress != 0">
            <div class="all-progress">
              <div class="progress" :style="{ width: allProgress + '%' }" v-bind:class="{ green: allProgress == 100 }"></div>
            </div>
            <div class="small-progress">图片上传总进度条：{{ allProgress + '%' }}</div>
          </div>
          <div class="up-con">
            <div class="up-left">
              <div>
                <span class="title">新闻标题：</span>
                <el-input v-model="post.title" placeholder ref="postTitle_m"></el-input>
              </div>
              <div>
                <span class="title">目标稿库：</span>
                <el-radio-group v-model="post.targetStore" class="contentradio">
                  <el-radio v-for="upStore in userInfo.upPicStores" :key="'store_' + upStore.id" class="radio" :label="upStore.id">{{ upStore.name }}</el-radio>
                </el-radio-group>
              </div>
              <div>
                <span class="title">发生时间：</span>
                <el-date-picker v-model="post.eventTime" type="date" value-format="yyyy-MM-dd" :default-value="new Date()" placeholder="选择日期"></el-date-picker>
              </div>
              <div>
                <span class="title">发生地点：</span>
                <el-cascader
                  class="content"
                  :options="$rtm.store.addrTree"
                  :props="types_picker_props"
                  filterable
                  clearable
                  separator=" > "
                  v-model="addr_picker_arr"
                  popper-class="addr-select-popper"
                ></el-cascader>
              </div>
              <div>
                <span class="title">稿件类型1：</span>
                <el-select v-model="type_picker_arr1" filterable placeholder="请选择" clearable>
                  <el-option v-for="item in $rtm.store.typeTree" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
                <!-- <el-cascader :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr1"></el-cascader> -->
              </div>
              <div>
                <span class="title">稿件类型2：</span>
                <el-select v-model="type_picker_arr2" filterable placeholder="请选择" clearable>
                  <el-option v-for="item in $rtm.store.typeTree" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
                <!-- <el-cascader :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr2"></el-cascader> -->
              </div>
              <div>
                <span class="title">稿件类型3：</span>
                <el-select v-model="type_picker_arr3" filterable placeholder="请选择" clearable>
                  <el-option v-for="item in $rtm.store.typeTree" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
                <!-- <el-cascader :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr3"></el-cascader> -->
              </div>
              <div>
                <span class="title">署名：</span>
                <el-input class="half" v-model="post.author" placeholder></el-input><span class="input-tip">（署名必须是中文）</span>
              </div>
              <div class="activity" v-if="atopics.length != 0">
                <span>参加投稿活动:</span>
                <el-checkbox-group v-model="acvititycCheck" @change="onChangeAt">
                  <el-checkbox v-for="acvitity in atopics" :key="acvitity.id" :label="acvitity.id"
                    >{{ acvitity.shortTitle }}<a :href="'activityPage.html?code=' + acvitity.id" target="_blank">(打开活动)</a></el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="up-right">
              <div>
                稿件正文：
                <el-input v-model="post.mainText" type="textarea" :rows="7" ref="postMainText_m"></el-input>
              </div>
              <div>
                备注：
                <el-input v-model="post.note" type="textarea" :rows="4" ref="postNote_m"></el-input>
              </div>
              <!-- <div>稿件标签：</div> -->
              <!-- <div class="postTag"> -->
              <!-- <div class="post-tag"> -->
              <!-- <div
                    v-for="h in postTagsList"
                    :key="h"
                    class="tags"
                    @click="oneditTags"
                  >
                    <input
                      class="edittag"
                      type="text"
                      v-if="editTagShow"
                      v-model="editTag"
                      @focus="ongetFocus(h)"
                    />
                    <div class="autoTag" v-else>{{ h }}</div>

                    <div class="el-icon-close"></div>
                  </div>
                  <input type="text" class="inputTag" /> -->
              <!-- <el-tag :key="tag" v-for="tag in postTagsList" closable :disable-transitions="false" @click="changeInput(tag)" @close="handleClose(tag)">{{ tag }}</el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="editTagShow"
                    v-model="newEdit"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="onEditTags"
                    @blur="onEditTags"
                  ></el-input>
                  <el-button v-else class="button-new-tag" size="small" @click="onAddTags">+</el-button>
                </div> -->
              <!-- <div class="born" @click="onBornPostTag(post.title, post.mainText, post.author)" title="根据正文和标题自动生成内容标签">生成标签</div> -->
              <!-- </div> -->
              <el-button type="primary" class="save" @click="onSavePost()">保存</el-button>
            </div>
          </div>
          <div class="add-pic">
            <el-button @click="onAddPic()">添加图片</el-button>
            <!-- <el-button v-if="pdfShow" @click="onAddPdf()">添加PDF图片</el-button> -->
            <!-- <el-button @click="onUsePicFileAsPicText()">以文件名作为图片说明</el-button> -->
          </div>
          <div class="pic-list">
            <!-- <div class="pic-item" v-for="(pic, picIndex) in picList" :key="pic.key" :class="{ moveUp: pic.moveUp, moveDown: pic.moveDown }"> -->
            <div class="pic-item" v-for="(pic, picIndex) in picList" :key="pic.key" :class="{ moveUp: pic.moveUp, moveDown: pic.moveDown }">
              <div class="othee">
                <div :style="{ backgroundImage: 'url(' + pic.url + ')' }" class="pic-image">
                  <i class="el-icon-top up" @click="onUpImage(pic.key)"></i>
                  <i class="el-icon-close close" @click="onDeleteImage(pic.key)"></i>
                  <i class="el-icon-bottom down" @click="onDownImage(pic.key)"></i>
                </div>
                <div class="pic-info">
                  <div class="progress" :style="{ width: pic.progress + '%' }" v-bind:class="{ green: pic.done == true }"></div>
                  <div class="status-line">
                    <div><el-button :disabled="!pic.textMsg" @click="onCopyPicText(picIndex)">复制本说明到其他无说明的图片</el-button></div>
                    <div class="mobild-status">
                      文件名：{{ pic.fileName }} 状态：{{ pic.done ? '已上传完毕' : '已上传' + pic.progress + '%' }}
                      <span v-if="!pic.textMsg" class="nopictext">(未填写图片小说明)</span>
                    </div>
                  </div>
                  <el-input type="textarea" class="text" :rows="4" placeholder="请输入图片说明" v-model="pic.text" resize="none" @input="onChangePicText(picIndex)"></el-input>
                </div>
              </div>
              <!-- <div class="tag" v-if="picList.length != 0">
                图片标签：
                <div class="tag-list">
                  <el-tag
                    :key="tag"
                    v-for="tag in pic.tags"
                    closable
                    :disable-transitions="false"
                    @click="changepictag(tag, picIndex)"
                    @close="onDelPicTag(tag, picIndex)"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tags"
                    v-if="pic.editPicTagShow"
                    v-model="newPicEdit"
                    :ref="'savepicTagInput_' + picIndex"
                    size="small"
                    @keyup.enter.native="onEditPicTags(picIndex)"
                    @blur="onEditPicTags(picIndex)"
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tags"
                    size="small"
                    @click="onAddPicTags(picIndex)"
                    >+</el-button
                  >
                </div>
                <div class="tag-del" @click="onGoback(picIndex)">一键恢复</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import axios from 'axios'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      canGoRich: false,
      post: {
        title: '',
        author: '',
        checked: false,
        mainText: '',
        note: '',
        type: [],
        tags: [],
        pics: [],
        eventTime: new Date().getFullYear() + '-' + ('' + (new Date().getMonth() + 1)).padStart(1, '0') + '-' + ('' + new Date().getDate()).padStart(1, '0'),
        targetStore: 0,
      },
      types_picker_props: {
        value: 'id',
        label: 'name',
        children: 'subs',
        checkStrictly: true,
      },
      type_picker_arr1: [],
      type_picker_arr2: [],
      type_picker_arr3: [],
      addr_picker_arr: [],
      picList: [],
      pdfList: [],
      atopics: [], // 活动的列表
      userInfo: {},
      postTagsList: [], // 稿件的tags
      editTagShow: false, // 编辑稿件标签
      editTag: '', // 新编辑后的标签
      newEdit: '', // 稿件标签的值
      newPicEdit: '', // 图片标签的值
      picUploading: false, //图片正在上传中
      picUploadingWaitingList: [], //图片上传等待列表
      pdfUploadingWaitingList: [],
      acvititycCheck: [],
      allProgress: 0, // 所有图片的总进度
      pdfShow: false, // PDF按钮的展示与隐藏
      pngShow: false, // png上传
      loading: false, //总的loading
      loadingTip: '',
    }
  },
  created() {
    const thiz = this
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        if (data.noStoreRights.indexOf('PDF_UPLOAD') != -1) {
          thiz.pdfShow = true
        }

        if (data.noStoreRights.indexOf('PNG_UPLOAD') != -1) {
          thiz.pngShow = true
        }

        if (data.upRichPicStores.length != 0) {
          thiz.canGoRich = true
        }
        if (data.approveStatus != 'APPROVED') {
          thiz.$alert('未审批账户不能上传稿件', '导航错误', {
            confirmButtonText: '确定',
            callback: (action) => {
              thiz.$router.replace('/myInfo.html')
            },
          })
          return
        }
        thiz.post.author = data.defaultAuthorName
        if (data.upPicStores.length != 0) {
          this.post.targetStore = data.upPicStores[0].id
        }
        RD.activityTopic()
          .forUpload()
          .then((topics) => {
            thiz.atopics = topics
            if (thiz.$route.query.atid != undefined) {
              thiz.atSelect(thiz.$route.query.atid)
            }
          })
        thiz.userInfo = data
      })
      .catch(() => {
        console.log('catch')
        this.$alert('您当前处于未登录状态, 请先登录?', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        })
          .then(() => {
            this.$router.push('/picture.html')
          })
          .catch(() => {})
      })

    this.reload()
  },
  mounted() {
    // const thiz = this
    // document.ondragover = function(e) {
    //   e.stopPropagation()
    //   e.preventDefault()
    // }
    // document.ondrop = function(e) {
    //   e.stopPropagation()
    //   e.preventDefault()
    // }
    // this.$refs.upPage.ondragover = function(e) {
    //   e.stopPropagation()
    //   e.preventDefault()
    // }
    // this.$refs.upPage.ondrop = function(e) {
    //   e.stopPropagation()
    //   e.preventDefault()
    //   console.log(e.dataTransfer)
    //   var list = e.dataTransfer.files
    //   for (var i = 0; i < list.length; i++) {
    //     var f = list[i]
    //     thiz.dealWithFile(f)
    //   }
    // }

    const thiz = this
    this.$refs.upPage.ondragover = function (e) {
      if (e.dataTransfer.items.length == 0) {
        e.stopPropagation()
        e.preventDefault()
      } else {
        var f = e.dataTransfer.items[e.dataTransfer.items.length - 1]
        if (f.kind != 'string') {
          e.stopPropagation()
          e.preventDefault()
        }
      }
    }
    this.$refs.upPage.ondrop = function (e) {
      if (e.dataTransfer.items.length == 0) {
        e.stopPropagation()
        e.preventDefault()
      } else {
        var f = e.dataTransfer.items[e.dataTransfer.items.length - 1]
        if (f.kind != 'string') {
          e.stopPropagation()
          e.preventDefault()
        }
      }
      var list = e.dataTransfer.files
      for (var i = 0; i < list.length; i++) {
        var f2 = list[i]
        thiz.dealWithFile(f2)
      }
    }

    this.$refs.postTitle.$refs.input.ondrop = function (e) {
      e.stopPropagation()
    }
    this.$refs.postTitle_m.$refs.input.ondrop = function (e) {
      e.stopPropagation()
    }

    this.$refs.postMainText.$refs.textarea.ondrop = function (e) {
      e.stopPropagation()
    }
    this.$refs.postMainText_m.$refs.textarea.ondrop = function (e) {
      e.stopPropagation()
    }
    this.$refs.postNote.$refs.textarea.ondrop = function (e) {
      e.stopPropagation()
    }
    this.$refs.postNote_m.$refs.textarea.ondrop = function (e) {
      e.stopPropagation()
    }
  },
  methods: {
    onSavePost() {
      // console.log('13', this.type_picker_arr1)
      if (this.picList.length == 0) {
        this.$rtm.message.error('没有上传图片！')
        return
      }
      var allPicDone = true
      for (var pic of this.picList) {
        if (!pic.done) {
          allPicDone = false
        }
      }
      if (!allPicDone) {
        this.$rtm.message.error('有图片不合法或者尚未上传完毕，不能保存！')
        return
      }
      if (this.post.title.length == 0) {
        this.$rtm.message.error('标题不能为空')
        return
      }
      if (this.post.targetStore == 0) {
        this.$rtm.message.error('请选择输出稿库')
        return
      }
      if (this.addr_picker_arr.length == 0) {
        this.$rtm.message.error('请选则发生地点')
        return
      }
      if (this.addr_picker_arr.length == 1) {
        this.$rtm.message.error('')
        return
      }
      if (this.type_picker_arr1.length == 0 && this.type_picker_arr2.length == 0 && this.type_picker_arr3.length == 0) {
        this.$rtm.message.error('请选择二级地点')
        return
      }
      if (this.post.author == undefined) {
        this.$rtm.message.error('请输入署名')
        return
      }
      if (this.post.eventTime.length == 0) {
        this.$rtm.message.error('请选择发生时间')
        return
      }
      if (this.post.mainText.length == 0) {
        this.$rtm.message.error('请输入正文')
        return
      }
      var post = {}
      post.storeId = this.post.targetStore
      post.title = this.post.title
      post.author = this.post.author
      post.mainText = this.post.mainText
      post.note = this.post.note
      post.eventTime = this.post.eventTime
      post.eventAddrId = this.addr_picker_arr[this.addr_picker_arr.length - 1]
      post.typeIds = []

      if (this.type_picker_arr1.length != 0) {
        post.typeIds.push(this.type_picker_arr1)
      }
      if (this.type_picker_arr2.length != 0) {
        post.typeIds.push(this.type_picker_arr2)
      }
      if (this.type_picker_arr3.length != 0) {
        post.typeIds.push(this.type_picker_arr3)
      }

      var target = []
      for (var i = 0; i < post.typeIds.length; i++) {
        var targetNumber = post.typeIds[i]
        if (target.indexOf(targetNumber) != -1) {
          break
        } else {
          target.push(targetNumber)
        }
      }
      post.typeIds = target
      post.tags = this.postTagsList
      post.acts = this.acvititycCheck
      post.pics = []
      var textChange = false
      for (var picInList of this.picList) {
        var textl = picInList.text.trim()
        if (textl.length == 0) {
          textChange = true
        }
        post.pics.push({
          uuid: picInList.uuid,
          text: picInList.text,
        })
      }

      if (textChange) {
        this.$confirm('有图片小说明尚未填写', '提示', {
          confirmButtonText: '不填写继续保存',
          cancelButtonText: '关闭并去填写',
          type: 'warning',
        })
          .then(() => {
            console.log('mxpost', post)
            RD.myPost()
              .upPost(post)
              .then(() => {
                this.$router.push('/myPost.html')
              })
          })
          .catch(() => {
            console.log('catch', post)
          })
      } else {
        console.log('finnly', post)
        RD.myPost()
          .upPost(post)
          .then(() => {
            this.$router.push('/myPost.html')
          })
      }

      // this.loadingTip = '稿件提交中，请耐心等待'
      // this.loading = true
      // RD.pure()
      //   .myPost()
      //   .upPost(post)
      //   .then((postId) => {
      //     setTimeout(() => {
      //       this.loadingTip = '稿件已经提交，等待提交后的预处理完成'
      //       this.afterPostUploadCheck(postId)
      //     }, 1000)
      //     // this.$router.push('/myPost.html')
      //   })
      //   .catch((error) => {
      //     this.loading = false
      //     thiz.$alert('上传失败：' + error.msg)
      //     console.log('catch', error, post)
      //   })
    },
    afterPostUploadCheck(postId) {
      RD.pure()
        .myPost()
        .checkPostAfterWork(postId)
        .then(() => {
          this.loading = false
          this.$alert('上传完毕，请到我的稿件中查看上传的稿件', {
            showClose: false,
          }).then(() => {
            this.$router.push('/myPost.html')
          })
        })
        .catch((error) => {
          this.loadingTip = '稿件已经提交，提交后的预处理进行中，' + error.msg
          setTimeout(() => {
            this.afterPostUploadCheck(postId)
          }, 300)
        })
    },
    // 改变投稿活动的勾选
    onChangeAt() {
      // if (this.acvititycCheck.length != 0) {
      //   this.post.targetStore = 131072
      // }
    },
    findPicIndexByKey(key) {
      for (var i = 0; i < this.picList.length; i++) {
        if (this.picList[i].key == key) {
          return i
        }
      }
      return -1
    },
    onUpImage(key) {
      var picIndex = this.findPicIndexByKey(key)
      if (picIndex == 0) {
        this.$message({
          type: 'warning',
          message: '已经到顶!',
        })
        return
      }
      var nodeToDown = this.picList[picIndex - 1]
      var nodeToUp = this.picList[picIndex]
      nodeToDown.moveDown = true
      nodeToUp.moveUp = true
      this.$nextTick(() => {
        setTimeout(() => {
          nodeToDown.moveDown = false
          nodeToUp.moveUp = false
          this.picList.splice(picIndex - 1, 0, this.picList.splice(picIndex, 1)[0])
        }, 300)
      })
    },
    onDeleteImage(key) {
      const thiz = this
      var picIndex = this.findPicIndexByKey(key)
      this.$confirm('将要删除选择的图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        for (var i = 0; i < thiz.picList.length; i++) {
          if (i > picIndex) {
            thiz.picList[i].moveUp = true
          }
        }
        thiz.$nextTick(() => {
          setTimeout(() => {
            thiz.picList.forEach((pic) => {
              pic.moveUp = false
            })
            this.picList.splice(picIndex, 1)
            thiz.$message({
              type: 'success',
              message: '删除成功!',
            })
          }, 300)
        })
      })
    },
    onDownImage(key) {
      var picIndex = this.findPicIndexByKey(key)
      if (picIndex == this.picList.length - 1) {
        this.$message({
          type: 'warning',
          message: '已经到底!',
        })
        return
      }
      var nodeToDown = this.picList[picIndex]
      var nodeToUp = this.picList[picIndex + 1]
      nodeToDown.moveDown = true
      nodeToUp.moveUp = true
      this.$nextTick(() => {
        setTimeout(() => {
          nodeToDown.moveDown = false
          nodeToUp.moveUp = false
          this.picList.splice(picIndex + 1, 0, this.picList.splice(picIndex, 1)[0])
        }, 300)
      })
    },
    dealWithFile(file) {
      const thiz = this
      var name = file.name
      var size = file.size
      console.log('文件名：' + name + ' 文件类型：' + size)
      if (!thiz.pngShow) {
        if (!name.toLocaleLowerCase().endsWith('.jpg') && !name.toLocaleLowerCase().endsWith('.jpeg')) {
          this.$alert('当然账户没有上传png的权限', '提示', {
            confirmButtonText: '确定',
            callback: (action) => {},
          })
          return
        }
      }

      var pic = {}
      pic.key = parseInt(Math.random() * 10000000)
      pic.fileName = name
      pic.size = size
      pic.done = false
      pic.progress = 0
      pic.moveUp = false
      pic.moveDown = false
      pic.editPicTagShow = false
      pic.text = ''
      pic.textMsg = false
      pic.tags = []
      var url
      if (window.navigator.userAgent.indexOf('Chrome') >= 1 || window.navigator.userAgent.indexOf('Safari') >= 1) {
        url = window.webkitURL.createObjectURL(file)
      } else {
        url = window.URL.createObjectURL(file)
      }
      pic.showUrl = undefined
      pic.url = url
      thiz.picList.push(pic)
      ;((file, key) => {
        thiz.appendPicUploadWork(file, key)
      })(file, pic.key)
      var img = new Image()
      img.onload = function () {
        var originWidth = img.width
        var originHeight = img.height
        var targetWidth
        var targetHeight
        if (originWidth > originHeight) {
          targetWidth = 300
          targetHeight = parseInt((originHeight * 300) / originWidth)
        } else {
          targetHeight = 300
          targetWidth = parseInt((originWidth * 300) / originHeight)
        }
        console.log('图片原始宽度=', originWidth)
        console.log('图片原始高度=', originHeight)
        console.log('图片目标宽度=', targetWidth)
        console.log('图片目标高度=', targetHeight)
        var tempCanvas = document.createElement('canvas')
        tempCanvas.setAttribute('class', 'temp-canvas')
        tempCanvas.width = targetWidth
        tempCanvas.height = targetHeight
        var ctx = tempCanvas.getContext('2d')
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight)
        var showUrl = tempCanvas.toDataURL('image/png')
        pic.showUrl = showUrl
      }
      img.src = url
    },
    appendPicUploadWork(file, key) {
      this.picUploadingWaitingList.unshift({
        file: file,
        key: key,
      })
      if (!this.picUploading) {
        this.uploadWithProgress()
      }
    },
    calculateAllProgress() {
      const thiz = this
      var allProgressA = 0
      for (var pic of thiz.picList) {
        allProgressA += pic.progress
      }
      thiz.allProgress = parseInt((allProgressA / thiz.picList.length) * 100) / 100
    },
    uploadWithProgress() {
      this.picUploading = true
      var workItem = this.picUploadingWaitingList.pop()
      if (!workItem) {
        this.picUploading = false
        return
      }
      var file = workItem.file
      var key = workItem.key
      const thiz = this
      let param = new FormData()
      param.append('file', file)
      axios({
        url: RD.myPost().upPic_URL(),
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: param,
        withCredentials: true,
        onUploadProgress: function (progressEvent) {
          for (var pic of thiz.picList) {
            if (pic.key == key) {
              pic.progress = parseInt((progressEvent.loaded * 100) / progressEvent.total)
              thiz.calculateAllProgress()
            }
          }
        },
      })
        .then((data) => {
          console.log('upPicResult', data)
          thiz.picUploading = false
          this.uploadWithProgress()
          if (data.data.code == 0) {
            for (var pic of thiz.picList) {
              if (pic.key == key) {
                pic.done = true
                pic.uuid = data.data.data.uuid
                pic.tags = data.data.data.tags
              }
              pic.editPicTagShow = false
              // pic.prePictag = JSON.parse(JSON.stringify(pic.tags))
            }
          } else {
            ;((toCheckKey, msg) => {
              console.log(toCheckKey, msg)
              this.delErrorPic(toCheckKey, msg)
            })(key, data.data.msg)
          }
        })
        .catch((errMsg) => {
          thiz.picUploading = false
          this.uploadWithProgress()
          console.log('upPicFail-msg', errMsg)
          this.delErrorPic(key, errMsg)
        })
    },
    delErrorPic(key, msg) {
      const thiz = this
      this.$confirm(msg + '即将要删除，请重新上传图片', '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning',
      }).then(() => {
        var picIndex = this.findPicIndexByKey(key)
        for (var i = 0; i < thiz.picList.length; i++) {
          if (i > picIndex) {
            thiz.picList[i].moveUp = true
          }
        }
        thiz.$nextTick(() => {
          setTimeout(() => {
            thiz.picList.forEach((pic) => {
              pic.moveUp = false
            })
            this.picList.splice(picIndex, 1)
            thiz.$message({
              type: 'success',
              message: '删除成功!',
            })
          }, 300)
        })
      })
    },
    fileChange(event) {
      console.log(event.target.files)
      for (var file of event.target.files) {
        this.dealWithFile(file)
      }
    },

    onAddPic() {
      this.$refs.upfileSelector.click()
    },
    onUsePicFileAsPicText() {
      console.log('onUsePicFileAsPicText')
      this.picList.forEach((pic) => {
        console.log('onUsePicFileAsPicText', pic.fileName)
        pic.text = pic.fileName.toLowerCase().replace('.jpg', '').replace('.jpeg', '')
      })
    },
    reload() {
      const thiz = this
      if (this.$rtm.store.typeTree.length == 0) {
        RD.type()
          .tree()
          .then((data) => {
            this.$rtm.store.typeTree = data
          })
      }
      if (this.$rtm.store.addrTree.length == 0) {
        RD.addr()
          .tree()
          .then((data) => {
            this.$rtm.store.addrTree = data
          })
      }
      thiz.type_picker_arr = thiz.treeList
    },
    // 自动生成稿件标签
    onBornPostTag(title, maintext, author) {
      if (this.post.title == '') {
        this.$message('新闻标题不能为空')
        return
      }
      if (this.post.mainText == '') {
        this.$message('稿件正文不能为空')
        return
      }
      if (this.post.author == '') {
        this.$message('署名不能为空')
        return
      }
      RD.myPost()
        .analyzeTags(title, maintext, author)
        .then((data) => {
          data.forEach((k) => {
            console.log('klength', k)
            // k.tagWidth = k.length * 20;
          })
          this.postTagsList = data
        })
    },
    // 编辑标签
    onEditTags() {
      let taginputValue = this.newEdit
      if (taginputValue !== '') {
        if (this.postTagsList.indexOf(taginputValue) === -1) {
          this.postTagsList.push(taginputValue)
        }
      }
      this.editTagShow = false
      this.newEdit = ''
    },
    // 点击编辑标签
    changeInput(tags) {
      console.log('focus', tags)
      this.postTagsList.splice(this.postTagsList.indexOf(tags), 1)
      this.editTagShow = true
      this.$nextTick(function () {
        this.$refs.saveTagInput.focus()
      })
      this.newEdit = tags
    },
    // 删除稿件标签
    handleClose(tag) {
      this.postTagsList.splice(this.postTagsList.indexOf(tag), 1)
    },
    // 新增稿件标签
    onAddTags() {
      console.log('addtag')
      this.editTagShow = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    // 点击图片的标签
    changepictag(tags, picarrIndex) {
      this.picList[picarrIndex].tags.splice(this.picList[picarrIndex].tags.indexOf(tags), 1)
      this.picList[picarrIndex].editPicTagShow = true
      this.$nextTick(function () {
        this.$nextTick(function () {
          console.log('shs', this.$refs['savepicTagInput_' + picarrIndex][0])
          this.$refs['savepicTagInput_' + picarrIndex][0].focus()
        })
      })
      this.newPicEdit = tags
    },
    // 删除图片的标签
    onDelPicTag(tag, picIndex) {
      this.$nextTick(function () {
        this.picList[picIndex].tags.splice(this.picList[picIndex].tags.indexOf(tag), 1)
      })
    },
    // 编辑图片标签
    onEditPicTags(picIndex) {
      let tagPicValue = this.newPicEdit
      if (tagPicValue !== '') {
        if (this.picList[picIndex].tags.indexOf(tagPicValue) === -1) {
          this.picList[picIndex].tags.push(tagPicValue)
        }
      }
      this.picList[picIndex].editPicTagShow = false
      this.newPicEdit = ''
    },
    // 添加图片标签
    onAddPicTags(picarrIndex) {
      this.picList[picarrIndex].editPicTagShow = true
      this.$nextTick(() => {
        this.$nextTick(() => {
          console.log('hjj', this.picList)

          console.log('ussu', this.$refs['savepicTagInput_' + picarrIndex][0])
          this.$refs['savepicTagInput_' + picarrIndex][0].focus()
        })
      })
    },
    // 一键恢复
    onGoback(picIndex) {
      this.picList[picIndex].tags = JSON.parse(JSON.stringify(this.picList[picIndex].prePictag))
    },
    // 投稿页进入的上传稿件
    atSelect(atId) {
      var thiz = this
      var matched = false
      thiz.atopics.forEach((k) => {
        if (k.id == atId) {
          thiz.post.targetStore = 131072
          thiz.acvititycCheck.push(parseInt(atId))
          matched = true
        }
      })
      if (!matched) {
        thiz.$message('您未有此活动的入稿权,或此活动未开始或已结束')
      }
    },
    //  图片说明是否为空的操作
    onChangePicText(picIndex) {
      var thiz = this
      // console.log('picChange', thiz.picList[picIndex])
      var picText = thiz.picList[picIndex].text
      var picLength = picText.trim()
      if (picLength.length != 0) {
        thiz.picList[picIndex].textMsg = true
      } else {
        thiz.picList[picIndex].textMsg = false
      }
    },
    // 复制无图片说明操作
    onCopyPicText(picIndex) {
      var thiz = this
      const picText = thiz.picList[picIndex].text
      thiz.picList.forEach((pic) => {
        const picL = pic.text.trim()
        if (picL.length == 0) {
          pic.text = picText
          pic.textMsg = true
        }
      })
    },
    // pdf上传
    onAddPdf() {
      this.$refs.upPftfileSelector.click()
    },
    filePdfChange(event) {
      for (var file of event.target.files) {
        this.dealPdfWithFile(file)
      }
    },
    dealPdfWithFile(file) {
      const thiz = this
      var name = file.name
      var size = file.size
      console.log('文件名：' + name + ' 文件类型：' + size)
      if (!name.toLocaleLowerCase().endsWith('.pdf') && !name.toLocaleLowerCase().endsWith('.PDF')) {
        return
      }
      var pic = {}
      pic.key = parseInt(Math.random() * 10000000)
      pic.fileName = name
      pic.size = size
      pic.done = false
      pic.progress = 0
      pic.moveUp = false
      pic.moveDown = false
      pic.editPicTagShow = false
      pic.text = ''
      pic.textMsg = false
      pic.tags = []
      var url
      if (window.navigator.userAgent.indexOf('Chrome') >= 1 || window.navigator.userAgent.indexOf('Safari') >= 1) {
        url = window.webkitURL.createObjectURL(file)
      } else {
        url = window.URL.createObjectURL(file)
      }
      pic.showUrl = undefined
      pic.url = url
      thiz.pdfList.push(pic)
      ;((file, key) => {
        thiz.appendPdfUploadWork(file, key)
      })(file, pic.key)
      var img = new Image()
      img.onload = function () {
        var originWidth = img.width
        var originHeight = img.height
        var targetWidth
        var targetHeight
        if (originWidth > originHeight) {
          targetWidth = 300
          targetHeight = parseInt((originHeight * 300) / originWidth)
        } else {
          targetHeight = 300
          targetWidth = parseInt((originWidth * 300) / originHeight)
        }
        console.log('图片原始宽度=', originWidth)
        console.log('图片原始高度=', originHeight)
        console.log('图片目标宽度=', targetWidth)
        console.log('图片目标高度=', targetHeight)
        var tempCanvas = document.createElement('canvas')
        tempCanvas.setAttribute('class', 'temp-canvas')
        tempCanvas.width = targetWidth
        tempCanvas.height = targetHeight
        var ctx = tempCanvas.getContext('2d')
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight)
        var showUrl = tempCanvas.toDataURL('image/png')
        pic.showUrl = showUrl
      }
      img.src = url
    },
    appendPdfUploadWork(file, key) {
      console.log('pdfwork', file)
      this.pdfUploadingWaitingList.unshift({
        file: file,
        key: key,
      })
      if (!this.picUploading) {
        this.uploadPdfWithProgress()
      }
    },
    uploadPdfWithProgress() {
      this.picUploading = true
      var workItem = this.pdfUploadingWaitingList.pop()
      if (!workItem) {
        this.picUploading = false
        return
      }
      var file = workItem.file
      var key = workItem.key
      const thiz = this
      let param = new FormData()
      param.append('file', file)
      axios({
        url: RD.myPost().upPDF_URL(),
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: param,
        withCredentials: true,
        onUploadProgress: function (progressEvent) {
          console.log('pdfsuccess', progressEvent)

          // for (var pic of thiz.pdfList) {
          //   if (pic.key == key) {
          //     // pic.progress = parseInt((progressEvent.loaded * 100) / progressEvent.total)
          //     // thiz.calculateAllProgress()
          //   }
          // }
        },
      })
        .then((data) => {
          console.log('upPicResult', data)
          thiz.picUploading = false
          this.uploadPdfWithProgress()
          if (data.data.code == 0) {
            RD.myPost()
              .checkPDFAnalyzeStatus(data.data.data)
              .then((info) => {
                console.log('jk', info)
                this.loading = false
              })
            // for (var pic of thiz.picList) {
            //   if (pic.key == key) {
            //     pic.done = true
            //     pic.uuid = data.data.data.uuid
            //     pic.tags = data.data.data.tags
            //   }
            //   pic.editPicTagShow = false
            //   // pic.prePictag = JSON.parse(JSON.stringify(pic.tags))
            // }
          } else {
            ;((toCheckKey, msg) => {
              console.log(toCheckKey, msg)
              this.delErrorPic(toCheckKey, msg)
            })(key, data.data.msg)
          }
        })
        .catch((errMsg) => {
          thiz.picUploading = false
          this.uploadPdfWithProgress()
          console.log('upPicFail-msg', errMsg)
          this.delErrorPic(key, errMsg)
        })
    },
  },
}
</script>
<style lang="less">
.temp-canvas {
  height: 300px;
  width: 300px;
  display: block;
  position: fixed;
  top: -1000px;
  left: 0px;
}
.addr-select-popper .el-radio__inner {
  border: 1px solid #cf0000 !important;
}
</style>
<style scoped lang="less">
.moveUp {
  transition: all 300ms ease-in-out;
  top: -16rem !important;
}
.moveDown {
  transition: all 300ms ease-in-out;
  top: 16rem !important;
}
.invisible {
  width: 0rem;
  height: 0rem;
  opacity: 0;
}
.uppost.desktop-present {
  background-color: #f1f1f1;
  min-height: calc(100vh - 32.7rem);
  .uppost-con {
    width: 120rem;
    margin: 0 auto;
    background-color: #ccc;
    .uppost-title {
      height: 6rem;
      width: 100%;
      text-align: left;
      line-height: 6rem;
      font-size: 1.2rem;
      margin-left: 0.5rem;
    }
    .uppost-detail {
      width: 100%;
      .tip {
        padding: 1.5rem 3rem;
        border: 0.1rem solid #f3e8c8;
        background-color: #fffeef;
        text-align: left;
        font-size: 1.3rem;
        line-height: 35px;
        .red {
          color: red;
          font-size: 1.4rem;
        }
      }
      .allProgress-ontainer {
        height: 5rem;
        margin-left: 1rem;
        .small-progress {
          height: 3rem;
          line-height: 3rem;
          font-size: 1.5rem;
          color: red;
        }
        .all-progress {
          width: 100%;
          height: 1rem;
          position: relative;
          background-color: rgba(255, 0, 0, 0.4);
          border-radius: 0.5rem;
          overflow: hidden;
          .progress {
            display: block;
            height: 1rem;
            position: absolute;
            left: 0rem;
            top: 0rem;
            background-color: green;
          }
          .progress.green {
            background-color: green;
          }
        }
      }
      .small-pic-preview {
        width: 100%;
        overflow: hidden;
        text-align: left;
        background-color: #ccc;
        img {
          height: 10rem;
          display: inline-block;
          margin: 1rem;
        }
      }
      .pic-list {
        width: 100%;
        overflow: hidden;
        background-color: #ccc;
        .pic-item {
          position: relative;
          height: 18rem;
          width: 96%;
          margin: 1rem 2%;
          top: 0rem;
          .othee {
            display: flex;
            .pic-image {
              width: 28rem;
              height: 15rem;
              background: no-repeat 50% / contain;
              background-color: #716e6e;
              position: relative;
              overflow: hidden;
              margin: 0rem 2rem;

              i {
                display: block;
                line-height: 4rem;
                font-size: 2.5rem;
                background-color: rgba(48, 38, 38, 0.4);
                color: rgb(226, 223, 223);
                border-radius: 2rem;
                overflow: hidden;
                height: 4rem;
                width: 4rem;
                right: -5rem;
              }
              .up {
                position: absolute;
                transition: all 200ms ease-in-out;
                top: 0.5rem;
              }
              .close {
                position: absolute;
                transition: all 200ms ease-in-out;
                top: 5.5rem;
              }
              .down {
                position: absolute;
                transition: all 200ms ease-in-out;
                top: 10.5rem;
              }
            }
            .pic-image:hover {
              i {
                right: 5rem;
              }
            }
            .pic-info {
              flex: 1;
              height: 15rem;
              display: flex;
              flex-direction: column;
              position: relative;
              font-size: 1.6rem;
              .status-line {
                height: 4.5rem;
                line-height: 4.5rem;
                text-align: left;
                .el-button {
                  margin-right: 1.5rem;
                  padding: 0.8rem;
                }
                span {
                  margin-left: 1.5rem;
                }
                .nopictext {
                  color: red;
                }
              }
              .text {
                flex: 1;
              }
              .progress-container {
                height: 1rem;
                width: 100%;
                position: relative;
                background-color: rgba(255, 0, 0, 0.4);
                border-radius: 0.5rem;
                overflow: hidden;
                .progress {
                  display: block;
                  height: 1rem;
                  position: absolute;
                  left: 0rem;
                  top: 0rem;
                  background-color: green;
                }
                .progress.green {
                  background-color: green;
                }
              }
            }
          }
          .tag {
            height: auto;
            text-align: left;
            font-size: 1.3rem;
            margin-left: 2rem;
            margin-top: 2rem;
            display: flex;
            width: 80rem;

            .tag-list {
              border: 0.1rem solid #f6f6f6;
              height: auto;
              box-sizing: border-box;
              border-radius: 0.5rem;
              width: 60rem;
              .el-tag {
                margin: 0.8rem 0.2rem;
              }
              .button-new-tag {
                margin-left: 1rem;
                margin-top: 0.8rem;
                height: 3.2rem;
                line-height: 3rem;
                padding-top: 0;
                padding-bottom: 0;
              }
              .input-new-tags {
                width: 13rem;
                margin-left: 1rem;
                vertical-align: bottom;
              }
            }
            .tag-del {
              width: 8rem;
              height: 4.5rem;
              line-height: 4.5rem;
              margin-left: 2rem;
              border-radius: 0.5rem;
              background-color: #716e6e;
              color: #fff;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
      .up-con {
        border: 0.1rem solid #f3e8c8;
        background-color: #fffeef;
        padding: 1.5rem 3rem;
        line-height: 5rem;
        display: flex;
        font-size: 1.3rem;
        text-align: left;

        .up-left {
          width: 50%;
          .title {
            display: inline-block;
            width: 10rem;
          }
          .half {
            width: 20rem !important;
          }
          .input-tip {
            text-shadow: 0rem 0rem 0.5rem red;
          }
          .contentradio {
            margin-top: 1.6rem;
            width: 70%;
            .radio {
              display: inline-bloc;
              height: 3rem;
            }
          }
          .content {
            margin: 0.5rem 0rem;
            width: 70%;
            .radio {
              display: inline-bloc;
              height: 3remx;
            }
          }
          div {
            .el-input {
              width: 70%;
            }
          }
          .beizhu {
            margin-left: 8remx;
            line-height: 3rem;
          }
          .activity {
            height: auto;
            a {
              text-decoration: none;
              color: #000;
              margin-left: 1rem;
            }
          }
        }
        .up-right {
          width: 50%;
          .save {
            margin-top: 3rem;
            margin-left: 12rem;
            margin-right: auto;
            width: 30rem;
          }
          .postTag {
            height: auto;
            margin-top: 0rem;
            display: flex;
            .post-tag {
              width: 45.2rem;
              height: auto;
              box-sizing: border-box;
              border-radius: 0.5rem;
              background-color: #fff;
              border: 0.1rem solid #ccc;
              .el-tag {
                margin: 0.8rem 0.2rem;
              }
              .button-new-tags {
                margin-left: 1rem;
                margin-top: 0.8rem;
                height: 3.2rem;
                line-height: 3rem;
                padding-top: 0;
                padding-bottom: 0;
              }
              .input-new-tag {
                width: 13rem;
                margin-left: 1rem;
                vertical-align: bottom;
              }
            }
            .born {
              width: 10rem;
              height: 4.5rem;
              margin-left: 1rem;
              text-align: center;
              border: 0.1rem solid #ccc;
              border-radius: 0.5rem;
              cursor: pointer;
            }
          }
        }
      }
      .add-pic {
        height: 5rem;
        background: #ccc;
        margin: 1rem;
        text-align: left;
      }
    }
  }
}
.uppost.mobile-present {
  background-color: #f1f1f1;
  margin: 2rem 0rem;
  .uppost-con {
    width: 750px;
    margin: 0 auto;
    background-color: #ccc;
    .uppost-title {
      height: 6rem;
      width: 100%;
      text-align: left;
      line-height: 6rem;
      font-size: 14px;
      margin-left: 0.5rem;
    }
    .uppost-detail {
      width: 100%;
      .tip {
        padding: 1.5rem 3rem;
        border: 0.1rem solid #f3e8c8;
        background-color: #fffeef;
        text-align: left;
        font-size: 13px;
        line-height: 35px;
        .red {
          color: red;
          font-size: 1.4rem;
        }
      }
      .allProgress-ontainer {
        height: 5rem;
        margin-left: 1rem;
        margin-top: 10px;
        .small-progress {
          height: 3rem;
          line-height: 3rem;
          font-size: 1.5rem;
          color: red;
        }
        .all-progress {
          width: 100%;
          height: 1rem;
          position: relative;
          background-color: rgba(255, 0, 0, 0.4);
          border-radius: 0.5rem;
          overflow: hidden;
          .progress {
            display: block;
            height: 1rem;
            position: absolute;
            left: 0rem;
            top: 0rem;
            background-color: green;
          }
          .progress.green {
            background-color: green;
          }
        }
      }
      .small-pic-preview {
        width: 100%;
        overflow: hidden;
        text-align: left;
        background-color: #ccc;
        img {
          height: 10rem;
          display: inline-block;
          margin: 1rem;
        }
      }
      .pic-list {
        width: 100%;
        overflow: hidden;
        background-color: #ccc;
        .pic-item {
          position: relative;
          height: 180px;
          width: 96%;
          margin: 1rem 2%;
          top: 0rem;
          .othee {
            display: flex;
            .pic-image {
              width: 28rem;
              height: 180px;
              background: no-repeat 50% / contain;
              background-color: #716e6e;
              position: relative;
              overflow: hidden;
              margin: 0rem 2rem;
              i {
                display: block;
                line-height: 4rem;
                font-size: 2.5rem;
                background-color: rgba(48, 38, 38, 0.4);
                color: rgb(226, 223, 223);
                border-radius: 2rem;
                overflow: hidden;
                height: 4rem;
                width: 4rem;
                right: -5rem;
              }
              .up {
                position: absolute;
                transition: all 200ms ease-in-out;
                top: 0.5rem;
              }
              .close {
                position: absolute;
                transition: all 200ms ease-in-out;
                top: 5.5rem;
              }
              .down {
                position: absolute;
                transition: all 200ms ease-in-out;
                top: 10.5rem;
              }
            }
            .pic-image:hover {
              i {
                right: 5rem;
              }
            }
            .pic-info {
              flex: 1;
              height: 180px;
              display: flex;
              flex-direction: column;
              position: relative;
              width: 28rem;
              .status-line {
                height: 80px;
                text-align: left;
                font-size: 14px;
                span {
                  font-size: 14px;
                }
                .mobild-status {
                  line-height: 36px;
                }
                .nopictext {
                  color: red;
                }
              }
              .text {
                flex: 1;
              }
              .progress {
                display: block;
                height: 0.5rem;
                position: absolute;
                left: 0rem;
                top: 0rem;
                background-color: rgba(255, 0, 0, 0.4);
              }
            }
          }
          .tag {
            height: auto;
            text-align: left;
            font-size: 1.3rem;
            margin-left: 2rem;
            margin-top: 2rem;
            display: flex;
            width: 80rem;

            .tag-list {
              border: 0.1rem solid #f6f6f6;
              height: auto;
              box-sizing: border-box;
              border-radius: 0.5rem;
              width: 60rem;
              .el-tag {
                margin: 0.8rem 0.2rem;
              }
              .button-new-tag {
                margin-left: 1rem;
                margin-top: 0.8rem;
                height: 3.2rem;
                line-height: 3rem;
                padding-top: 0;
                padding-bottom: 0;
              }
              .input-new-tags {
                width: 13rem;
                margin-left: 1rem;
                vertical-align: bottom;
              }
            }
            .tag-del {
              width: 8rem;
              height: 4.5rem;
              line-height: 4.5rem;
              margin-left: 2rem;
              border-radius: 0.5rem;
              background-color: #716e6e;
              color: #fff;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
      .up-con {
        border: 0.1rem solid #f3e8c8;
        background-color: #fffeef;
        padding: 1.5rem 3rem;
        line-height: 5rem;
        display: flex;
        font-size: 1.3rem;
        text-align: left;

        .up-left {
          width: 50%;
          .title {
            display: inline-block;
            width: 100px;
            font-size: 14px;
          }
          .half {
            width: 20rem !important;
          }
          .input-tip {
            text-shadow: 0rem 0rem 0.5rem red;
          }
          .contentradio {
            margin-top: 1.6rem;
            width: 70%;
            .radio {
              display: inline-bloc;
              height: 3rem;
            }
          }
          .content {
            margin: 0.5rem 0rem;
            width: 70%;
            .radio {
              display: inline-bloc;
              height: 3remx;
            }
          }
          div {
            .el-input {
              width: 70%;
            }
          }
          .beizhu {
            margin-left: 8remx;
            line-height: 3rem;
          }
          .activity {
            height: auto;
            font-size: 14px;
            a {
              text-decoration: none;
              color: #000;
              margin-left: 1rem;
            }
          }
        }
        .up-right {
          width: 50%;
          font-size: 14px;
          .save {
            margin-top: 3rem;
            margin-left: 12rem;
            margin-right: auto;
            width: 30rem;
          }
          .postTag {
            height: auto;
            margin-top: 0rem;
            display: flex;
            .post-tag {
              width: 45.2rem;
              height: auto;
              box-sizing: border-box;
              border-radius: 0.5rem;
              background-color: #fff;
              border: 0.1rem solid #ccc;
              .el-tag {
                margin: 0.8rem 0.2rem;
              }
              .button-new-tags {
                margin-left: 1rem;
                margin-top: 0.8rem;
                height: 3.2rem;
                line-height: 3rem;
                padding-top: 0;
                padding-bottom: 0;
              }
              .input-new-tag {
                width: 13rem;
                margin-left: 1rem;
                vertical-align: bottom;
              }
            }
            .born {
              width: 10rem;
              height: 4.5rem;
              margin-left: 1rem;
              text-align: center;
              border: 0.1rem solid #ccc;
              border-radius: 0.5rem;
              cursor: pointer;
            }
          }
        }
      }
      .add-pic {
        height: 7rem;
        background: #ccc;
        margin: 1rem;
        text-align: left;
      }
    }
  }
}
</style>

<style lang="less">
.el-radio__input.is-checked .el-radio__inner {
  background-color: red;
  border-color: red;
}
.el-radio__input.is-checked + .el-radio__label {
  color: red;
}
</style>
